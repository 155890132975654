<template>
  <div class="nav-tabs-custom">
    <ul class="nav nav-tabs" ref="tabs">
      <li
        v-for="(lib, index) in libraries"
        :key="index"
        role="presentation"
        :class="{ active: currentTab == lib.name }"
        data-testid="tab"
      >
        <a
          :href="'#' + lib.prefix"
          data-toggle="tab"
          @click="currentTab = lib.name"
          >{{ lib.name }}</a
        >
      </li>
    </ul>
    <div class="input-group input-group-sm" style="margin: 0.8em 0">
      <input
        v-model="query"
        type="search"
        class="form-control"
        :placeholder="$t('search')"
        data-testid="search"
        ref="query"
      />
      <div class="input-group-btn">
        <button
          class="btn btn-default"
          @click="
            query = '';
            $refs.query.focus();
          "
          data-testid="reset"
        >
          <span class="fa fa-remove"></span>
        </button>
      </div>
    </div>
    <div class="tab-content">
      <div
        v-for="(lib, index) in libraries"
        :key="index"
        :class="['tab-pane', { active: currentTab == lib.name }]"
        :id="lib.prefix"
        data-testid="tab-content"
      >
        <div class="icon-info">
          <ul class="clearfix" :key="cid + index" ref="icons">
            <li
              v-for="(icon, index) in loadedIcons[lib.prefix]"
              :key="index"
              :title="
                icon.name +
                (icon.aliases.length
                  ? ` (${$tc('alias', 2).toLowerCase()}: ${icon.aliases.join(
                      ', '
                    )})`
                  : '')
              "
              @click.stop.prevent="select(lib.prefix, icon.name)"
              data-testid="icon"
              :class="{ active: isSelected(lib.prefix, icon.name) }"
            >
              <span
                :class="`icon ${lib.prefix} ${lib.prefix}-${icon.name}`"
              ></span>
              <span class="icon-name" data-testid="icon-name">{{
                icon.name
              }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconLibrary from "@/assets/dashboard/icons";
export default {
  name: "IconSelector",
  props: {
    value: {
      type: String,
      required: false,
      default: "glyphicon glyphicon-th-large"
    }
  },
  data() {
    return {
      currentTab: IconLibrary[0].name,
      query: "",
      cid: 0
    };
  },
  computed: {
    libraries() {
      return IconLibrary;
    },
    loadedIcons() {
      let entry = {};
      (IconLibrary || []).forEach((lib) => {
        entry[lib.prefix] = lib.icons.filter((icon) =>
          this.query
            ? icon.name.indexOf(this.query) != -1 ||
            this.$utils.queryStrAtr(this.query, icon.aliases)
            : true
        );
      });
      return entry;
    }
  },
  methods: {
    isSelected(prefix, name) {
      return `${prefix} ${prefix}-${name}` == this.value;
    },
    highlight() {
      if (this.value) {
        let iconClass = (` ${this.value || ''}`).split(' ').join('.');
        let lib = IconLibrary[iconClass.indexOf('.fa-') >= 0 ? 0 : 1];
        this.currentTab = lib.name;
        this.cid += 1;
        this.zIndex = ++window.floatPanelZIndex;
        this.$forceUpdate();
        let icon;
        this.$nextTick(() => {
          setTimeout(() => {
            for (var i in this.$refs.icons) {
              icon = this.$refs.icons[i].querySelector(`li > ${iconClass}`);
              if (icon) {
                icon.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
                break;
              }
            }
          }, 250, this);
        })
      }
    },
    toggle() {
      this.highlight();
    },
    reset() {
      this.query = "";
    },
    select(prefix, name) {
      this.$emit(
        "input",
        `${prefix} ${prefix}-${name}`
      );
    }
  },
  mounted() {
    this.highlight();
  }
}
</script>

<style lang="scss" scoped>
.nav-tabs-custom {
  // margin: 10px;
  box-shadow: none;

  .tab-content {
    position: relative;
    max-height: 280px;
    overflow: hidden auto;
  }

  .nav > li > a {
    padding: 6px 10px;
  }

  .icon-info {
    clear: both;

    ul {
      list-style: none;
      margin-bottom: 0;
      padding-left: 0;

      li {
        cursor: pointer;
        width: 104px;
        height: 65px;
        padding: 10px;
        text-align: center;
        overflow: visible;
        display: inline-block;
        text-align: center;
        vertical-align: text-top;
        border-radius: 10px;

        &.active {
          background-color: rgba(61, 100, 124, 0.29);
        }

        &:hover {
          background-color: rgba(86, 61, 124, 0.1);
        }

        .icon {
          font-size: 1.5em;
          margin-bottom: 5px;
        }

        .icon-name {
          font-size: 80%;
          display: block;
          line-height: 1em;
        }
      }
    }
  }
}
</style>